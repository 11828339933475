import { useSearchParams } from 'react-router-dom'

export default function ViewWord() {
  const [search, setsearch] = useSearchParams()
  const ID = search.get('ID')
  var parser = document.createElement('a');
  console.log("id = " + ID);
  parser.href = window.location.href;
  var hostname = "https://" + parser.hostname;
  const downloadurl = `${hostname}/api/r2/download/${ID}`
  const viewurl = `https://view.xdocin.com/view?src=${downloadurl}`;
  return (
    <div className='bg-slate-700 min-h-full'>
      <iframe src={viewurl} style={{ width:'100%', height:'900px'}} />
    </div>
  )
}