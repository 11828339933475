import React, { useState } from 'react';
import { Route, Navigate, useNavigate, Routes } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [user, setUser] = useState({});
 
  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleLogin = (e) => {
    const url = "/api/user/" + encodeURIComponent(btoa(username+password))
    console.log("url = " + url)
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
    })
      .then((res) => res.json())
      .then((res) => {
        console.log("result post2= ", res.type);
        if (res.type === 0) { // 用户是已登录状态
          setUser({"type": 1 })
          console.log("result user= ", user);
        }
        else if (res.type === 1) { // 用户登录成功
          setUser(res)
        }
        else {
          toast("账号不存在或输入错误")
          console.log("账号不存在");
        }
      })
      .catch((err) => {
        toast("程序错误");
        console.log(err.message);
      });
  };
  // console.log('================================', user)

  if (document.cookie.includes("uToken=4289bb36451d430abc6fae96de9e6307") || 
  document.cookie.includes("uToken=3C8E6A0C09094FEB9164C06B6AC69713"))  {
    console.log('=================有 cookie===============')
    user.type = 1;
  }
  else {
    console.log('=================没有 cookie===============', document.cookie)
  }
 

  return user.type ? (<Navigate to="/home" />) :  (
    <>
      <div className='container'>
        <div className='login-wrapper'>
        <ToastContainer />
          <div className="header">管理员登录</div>
          <div className="form-wrapper">
            <div>
              <input type="text" id="username" name="username" placeholder="输入用户名" className="input-item" value={username} onChange={handleUsernameChange} />
            </div>
            <div>
              <input type="password" id="password" name="password" placeholder="输入密码" className="input-item" value={password} onChange={handlePasswordChange} />
            </div>
            <div className="btn" onClick={handleLogin}>登录</div>
          </div>
        </div>
      </div>
      <style jsx="true">{`
         .container {
          height: 100%;
          background-image: linear-gradient(to right, #fbc2eb, #a6c1ee);
      }
      .login-wrapper {
          background-color: #fff;
          width: 358px;
          height: 588px;
          border-radius: 15px;
          padding: 0 50px;
          position: relative;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
      }
      .header {
          font-size: 38px;
          font-weight: bold;
          text-align: center;
          line-height: 200px;
      }
      .input-item {
          display: block;
          width: 100%;
          margin-bottom: 20px;
          border: 0;
          padding: 10px;
          border-bottom: 1px solid rgb(128, 125, 125);
          font-size: 15px;
          outline: none;
      }
      .input-item:placeholder {
          text-transform: uppercase;
      }
      .btn {
          text-align: center;
          padding: 10px;
          width: 100%;
          margin-top: 40px;
          background-image: linear-gradient(to right, #a6c1ee, #fbc2eb);
          color: #fff;
      }
      .msg {
          text-align: center;
          line-height: 88px;
      }
      a {
          text-decoration-line: none;
          color: #abc1ee;
      }
       
             `}</style>
    </>
  )
}
