import { Route, Navigate, useNavigate } from 'react-router-dom'
import { useEffect } from 'react';
 

export default function Home() {
  // function hasLogin() {
  //   loginTokens = context.env.LOGINTOKENS || [];
  //   console.log("==================================loginTokens", loginTokens)

  // }
  const navigate = useNavigate();
  // console.log("测试  10-%E4%BA%92%E8%81%94%E7%BD%91.docx")
  // const filename = decodeURIComponent("10-%E4%BA%92%E8%81%94%E7%BD%91.docx")
  // console.log("测试  ",filename)
  console.log("用户注册:", btoa("zhaojunwei" + "18911799475?"))

  var hasLogin = false;
  if (document.cookie.includes("uToken=4289bb36451d430abc6fae96de9e6307") || 
  document.cookie.includes("uToken=3C8E6A0C09094FEB9164C06B6AC69713")) {
    console.log('=================有 cookie===============')
    hasLogin = true;
  }
  else {
    console.log('=================没有 cookie===============', document.cookie)
  }
  return hasLogin ? (
    <div className='min-h-full'>
        <div className='mx-5 my-8'>
          <div className='text-xl font-bold'>欢迎来到 一键仲裁首页</div>
          <div className='my-8'>
            <div className='font-bold text-slate-600'>准备好word文档上传吧！</div>
            <div className='my-11 flex'>
              <button className="bg-violet-500 hover:bg-violet-600 active:bg-violet-700 focus:outline-none 
              focus:ring focus:ring-violet-300 leading-3 p-3 rounded-full text-white font-bold text-sm" onClick={()=>navigate('/word/upload')}>
                上传代理意见
              </button>
              <button className="bg-violet-500 hover:bg-violet-600 active:bg-violet-700 focus:outline-none 
              focus:ring focus:ring-violet-300 leading-3 p-3 rounded-full text-white font-bold mx-2 text-sm" onClick={()=>navigate('/word/list')}>
                查看已传文件
              </button>
            </div>
          </div>
        </div>
 
    </div>
  ) : ( <Navigate to="/user/login" /> ) 
}
