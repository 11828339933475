import './App.css';
import { Route, Navigate, Routes } from 'react-router-dom'
import Login from './components/user/login/login'
import Home from './components/home'
import UploadWord from './components/uploadword'
import WordList from './components/wordlist'
import ViewWord from './components/viewword';
import QRCode from './components/qrcode';

function App() {  
  return (
    <Routes>
      <Route path="/" element={<Navigate to="user/login" />} />
      <Route path="/home" element={<Home/>} />
      <Route path="/user/login" element={<Login/>} /> 
      <Route path="/word/upload" element={<UploadWord/>} /> 
      <Route path="/word/list" element={<WordList/>} /> 
      <Route path="/word/view" element={<ViewWord/>} /> 
      <Route path="/word/qrcode" element={<QRCode/>} /> 
    </Routes>
  );
}

export default App;
