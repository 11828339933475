import { Navigate, Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { useEffect, useState } from 'react';


export default function WordList() {
  const [listItems, setListItems] = useState([]);
  const navigate = useNavigate()

  function listItemsWithLists(_lists) {
    const _listItems = _lists.map((item) => {
      const filename = decodeURIComponent(atob(item.KEY));
      const size = (item.SIZE / 1024 / 1024).toFixed(2);
      const str = JSON.stringify(item)
      const url = btoa(str)
      console.log("url ", url)
      const location = window.location
      let baseUrl = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '');  
      const qrurl = `https://api.pwmqr.com/qrcode/create/?url=${baseUrl}/word/qrcode?item=${url}`

      return <li className="bg-indigo-100 p-3" key={item.KEY}>
        <div className="text-xl m-2">{filename} {size}M</div>
        <img className='m-2 hidden center' id={item.KEY} width={200} height={200} src={qrurl} /> 
        <div className="text-sm m-2 text-slate-600">上传日期：{item.UploadTimeStr}</div>
        <div className="flex flex-row">
          <button className="bg-green-600 hover:bg-green-700 text-white font-bold py-2 px-2 rounded m-1"
            onClick={(e) => qrAction(e)} item={str} >  获取二维码 </button>

          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 rounded m-1"
            onClick={(e) => viewAction(e)} ID={item.ID} > 查看 </button>

          <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 rounded m-1"
            onClick={(e) => downloadAction(e, filename)} ID={item.ID} > 下载 </button>

          <button className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-2 rounded m-1"
            onClick={(e) => delAction(e, _lists)} ID={item.ID}>删除</button>

        </div>
      </li>;
    });
    setListItems(_listItems);
  }
  function qrAction(e) {
    const imgs = document.getElementsByTagName("img")
    for (var i = 0; i < imgs.length; i++) {
        var item = imgs[i];
        if (item.style.display == "block") {
          item.style.display = "none";
        }
    }

    const img = e.target.parentNode.parentNode.getElementsByTagName("img")[0]
    img.style.display = "block"
    // navigate(`/word/qrcode?item=${}`)
  }

  const goViewWord = (ID) => {
    navigate(`/word/view?ID=${ID}`)
  }
  function viewAction(e) {
    const ID = e.target.getAttribute("ID");
    goViewWord(ID)
  }

  function fetchList() {
    fetch(`/api/r2/filelist/filelist`, {
      method: 'GET',
    })
      .then((res) => res.json())
      .then((res) => {
        const { code, lists } = res;
        if (code == 0) { // 成功 {"ID":1,"CreatedTime":"2024-06-19 18:01:30","KEY":"=","SIZE":2021853,"UploadTime":"2024-06-19 18:01:30","UploadTimeStr":"2024-06-19 18:01:30"}
          listItemsWithLists(lists);
        }
        else {
          toast("获取内容失败");
        }
      })
      .catch((err) => {
        toast.dismiss();
        toast("获取内容失败");
        console.log(err);
      });
  }

  useEffect(() => {
    fetchList();
  }, []);


  function delAction(e, lists) {
    const ID = e.target.getAttribute("ID");
    console.log(ID);

    // 删除数据库数据和存储桶数据
    fetch(`/api/r2/filelist/delete`, {
      method: 'POST',
      body: JSON.stringify({ "ID": ID })
    }).then((res) => res.json())
      .then((res) => {
        // 本地删除
        const updatedLists = lists.filter(item => item.ID != ID);
        listItemsWithLists(updatedLists);
        console.log(JSON.stringify(res));
      })
      .catch((err) => {
        toast.dismiss();
        toast("获取内容失败");
        console.log(err);
      });

  }

  function downloadAction(e, filename) {
    const ID = e.target.getAttribute("ID");
    // 下载
    fetch(`/api/r2/download/${ID}`, {
      method: 'GET'
    }).then(res => res.blob()).then((blob) => {
      var a = document.createElement('a');
      var url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = filename;
      a.click();
      window.URL.revokeObjectURL(url);
      toast('下载完成');
      console.log(url)
    });
  }

  var hasLogin = false;
  if (document.cookie.includes("uToken=4289bb36451d430abc6fae96de9e6307") ||
    document.cookie.includes("uToken=3C8E6A0C09094FEB9164C06B6AC69713")) {
    // console.log('=================有 cookie===============')
    hasLogin = true;
  }
  return hasLogin ? (
    <div className='bg-slate-700 min-h-full'>
      <ToastContainer />
      <div id="wordContainer"></div>

      <div className="w-full h-full flex content-center justify-center pt-10">
        <div className="bg-white shadow-md rounded px-8 py-8">
          <h1 className="block text-grey-800 text-xl font-bold mb-2">代理意见列表</h1>
          <ul className="flex flex-col divide-y divide-blue-200">
            {listItems}
          </ul>
        </div>
      </div>
    </div>
  ) : (<Navigate to="/user/login" />);
}
