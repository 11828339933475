/**
 * 上传代理意见word文件
 */
import { Route, Navigate, useNavigate, Routes } from 'react-router-dom';
import { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';

export default function UploadWord() {
  const [uploadFile, setUploadFile] = useState("")
  const navigate = useNavigate()

  const fileinputChange = (event) => {
    const fileData = event.target.files[0];
    // 获取到的文件 fileData
    if (fileData) {
      setUploadFile(fileData)
    }
  }

  const goWorldList = () => {
    navigate('/word/list')
  }

  function upload(btnelement) {
    const key = btoa(encodeURIComponent(uploadFile.name));
    document.getElementById("wordfile").setAttribute('disabled', 'disabled');
    document.getElementById("create").disabled = true;

    toast("文件上传中...");
    fetch(`/api/r2/savefile/${key}`, {
      method: 'PUT',
      body: uploadFile,    
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res)
        toast.dismiss();
        if (res.code == 0) { // 成功
          // const filename = decodeURIComponent(atob(res.key))
          // console.log("result filename = ", filename);
          // console.log("result size = ", res.size);
          // console.log("result uploaded = ", res.uploaded);
          // console.log("result code = ", res.code);
  
          toast("文件上传成功");
          console.log("上传成功 navigate to list");
          goWorldList()
        }
        else if (res.code == 1) {
          toast("文件已存在，请确认名称是否重复");
        }
        else {
          toast("上传失败")
        }

        document.getElementById("wordfile").removeAttribute('disabled');
        document.getElementById("create").disabled = false;
      })
      .catch((err) => {
        toast.dismiss();
        toast("文件上传失败");
        console.log(err);
        document.getElementById("wordfile").removeAttribute('disabled');
        document.getElementById("create").disabled = false;
      });

  }



  var hasLogin = false;
  if (document.cookie.includes("uToken=4289bb36451d430abc6fae96de9e6307") ||
    document.cookie.includes("uToken=3C8E6A0C09094FEB9164C06B6AC69713")) {
    // console.log('=================有 cookie===============')
    hasLogin = true;
  }
  return hasLogin ? (
    <div className='bg-slate-700 min-h-full'>
      <ToastContainer />
      <div className="w-full h-full flex content-center justify-center pt-10">
        <div className="bg-white shadow-md rounded px-8 py-8">
          <h1 className="block text-grey-800 text-xl font-bold mb-2">上传代理意见word文档</h1>
          <div className="flex">
            <input
              id='wordfile'
              className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-800 leading-tight focus:outline-none focus:shadow-outline"
              type="file" name="file"
              accept=".doc,.docx,.pdf"
              onChange={fileinputChange}
            />
            <button className="bg-blue-500 hover:bg-blue-800 text-white font-bold ml-2 py-2 px-4 rounded focus:outline-none focus:shadow-outline w-40 disabled:bg-gray-400"
              id="create" type="submit" onClick={upload}>开始上传</button>
          </div>
        </div>
      </div>
    </div>
  ) : (<Navigate to="/user/login" />)
}
