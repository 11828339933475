import { Navigate, Link, useNavigate, useSearchParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { useEffect, useState } from 'react';

export default function QRCode() {
  const navigate = useNavigate()
  const [search, setsearch] = useSearchParams()
  const itemstr = atob(search.get('item'))
  const item = JSON.parse(itemstr)
  const filename = decodeURIComponent(atob(item.KEY));
  const size = (item.SIZE / 1024 / 1024).toFixed(2);

  function downloadAction(e, filename) {
    const ID = e.target.getAttribute("ID");
    // 下载
    fetch(`/api/r2/download/${ID}`, {
      method: 'GET'
    }).then(res => res.blob()).then((blob) => {
      var a = document.createElement('a');
      var url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = filename;
      a.click();
      window.URL.revokeObjectURL(url);
      toast('下载完成');
      console.log(url)
    });
  }
  const goViewWord = (ID) => {
    navigate(`/word/view?ID=${ID}`)
  }
  function viewAction(e) {
    const ID = e.target.getAttribute("ID");
    goViewWord(ID)
  }

 
  return (
    <div className='bg-slate-700 min-h-full'>
      <ToastContainer />
      <div id="wordContainer"></div>

      <div className="w-full h-full flex content-center justify-center pt-10">
        <div className="bg-white shadow-md rounded px-8 py-8">
          <h1 className="block text-grey-800 text-xl font-bold mb-2">代理意见列表</h1>
          <ul className="flex flex-col divide-y divide-blue-200">
            <li className="bg-indigo-100 p-3" key={item.ID}>
              <div className="text-xl m-2">{filename} {size}M</div>
              <div className="text-sm m-2 text-slate-600">上传日期：{item.UploadTimeStr}</div>
              <div className="flex flex-row">
                
                <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 rounded m-1"
                  onClick={(e) => viewAction(e)} ID={item.ID} > 查看 </button>

                <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-2 rounded m-1"
                  onClick={(e) => downloadAction(e, filename)} ID={item.ID} > 下载 </button>
 
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}